<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="filterResults()">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0" v-if="filters">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  title="general.search"
                  :placeholder="$t('general.search_filter')"
                  :model.sync="filters.search"
                ></input-filter>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    title="general.start_date"
                    :model.sync="filters.start_date"
                    name="start_time"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    title="general.end_date"
                    :model.sync="filters.end_date"
                    name="end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    @click="filterResults()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    @click="exportInvoiceTaskTable"
                    type="button"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.export_to_excel") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row w-100 mx-0 py-5 px-5">
            <div
              @click="onChangeActiveNav(wait_approve_id)"
              :class="{
                'bg-primary text-white': filters.status_id === wait_approve_id,
              }"
              class="col-xl-6 col-lg-6 col-md-6 col-sm-6 py-3 cursor-pointer text-xl font-weight-bolder text-center border primary-border-1px border-right-0 border-radius-left-top-10px border-radius-left-bottom-10px"
            >
              {{ $t("invoice.wait_rejected") }}
            </div>
            <div
              @click="onChangeActiveNav(approve_id)"
              :class="{
                'bg-primary text-white': filters.status_id === approve_id,
              }"
              class="col-xl-6 col-lg-6 col-md-6 col-sm-6 cursor-pointer text-xl font-weight-bolder text-center py-3 border primary-border-1px border-radius-right-top-10px border-radius-right-bottom-10px"
            >
              {{ $t("invoice.approved") }}
            </div>
          </div>
          <div class="px-5 py-3">
            <data-table
              id="table"
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @hitBottom="onHitBottom"
              @rowClicked="onRowClicked"
              :items="formattedItems"
              :fields="fields"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :infiniteId="infiniteId"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import {
  CREATE_ITEM,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  RESET_VALUES,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  FILTER_TASK_ACTION,
  SET_FILTER_TASK_ACTION,
  TASK_EXPORT,
} from "@/core/services/store/invoice/incoming_invoice.module";

import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import {
  FINANCE_PAYMENTS_INDEX_START_DATE_STORAGE_NAME,
  MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import $ from "jquery";
import { invoiceFormat } from "@/core/filter/number.filter.js";

export default {
  name: "TaskAction",
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    DataTable,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_TASK_ACTION,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedItems() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      return this.items.data.map((item) => {
        let invoices = item.invoices;
        let tmpInvoices = [];
        // invoices.forEach((item) => {
        //   tmpInvoices.push(item.invoice);
        // });
        let invoiceTotal = 0;
        let invoiceNumber = "";
        invoices.forEach((tmp) => {
          console.log(tmp.total_amount_converted);
          if (
            tmp.total_amount_converted != null &&
            Number(tmp.total_amount_converted) >= 0
          ) {
            invoiceTotal += Number(tmp.total_amount_converted);
          } else {
            if (tmp.invoice.withholding_amount > 0) {
              invoiceTotal += Number(tmp.invoice.amount_to_be_paid);
            } else {
              invoiceTotal += Number(tmp.invoice.total_amount_including_taxes_converted);
            }
          }

          invoiceNumber += " " + tmp.invoice.invoice_no + ",";
        });
        return {
          id: item.id,
          company_name: item.supplier_company.name,
          start_date: moment(item.start_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
          end_date: moment(item.end_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          ),
          invoice_no: invoiceNumber,
          amount: invoiceFormat(invoiceTotal) + " TL",
        };
      });
    },
  },
  data() {
    return {
      url: "api/invoices/invoice-payment-request",
      items: { data: [] },
      approve_id: 46,
      infiniteId: 1,
      wait_approve_id: 45,
      sort: "desc",
      order: "id",
      fields: [
        {
          key: "company_name",
          label: this.$t("invoice.company_name"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "invoice_no",
          label: this.$t("invoice.invoice_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "start_date",
          label: this.$t("general.start_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "end_date",
          label: this.$t("general.end_date"),
          sortable: true,
          class:
            "text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
        {
          key: "amount",
          label: this.$t("invoice.index_amount"),
          sortable: true,
          class: "text-center border border-top-0 font-weight-bolder font-size-lg",
          tdClass: "cursor-pointer",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER_TASK_ACTION,
    }),

    onHitBottom($state = null) {
      let self = this;
      let filters = _.cloneDeep(this.filters);

      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: this.url,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          if (result.status) {
            self.items = result.data;
            if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },

    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        search: null,
        status_id: null,
        approve_id: null,
        page: 1,
        per_page: 20,
        sort: "",
        order: "",
      });
    },
    onRowClicked(item, index, event) {
      this.$router.push({
        name: "finance_payments.edit",
        params: {
          id: this.items.data[index].id,
        },
      });
    },
    filterResults() {
      localStorage.setItem(
        FINANCE_PAYMENTS_INDEX_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );

      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom(null);
    },
    exportInvoiceTaskTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(TASK_EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "invoice_task" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    onChangeActiveNav(value) {
      this.infiniteId++;
      this.filters.status_id = value;
      this.$store.commit(RESET_VALUES);
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.finance_payments") },
    ]);

    if (this.isUserGranted("Staff", ["invoiceChief"], false, true)) {
      this.wait_approve_id = 45;
      this.approve_id = 46;
      this.resetFilters();
    } else if (this.isUserGranted("Staff", ["financeManager"], false, true)) {
      this.wait_approve_id = 46;
      this.approve_id = 47;
      this.resetFilters();
    } else {
      this.sweetAlertError(this.$t("errors.404"));
      return false;
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      FINANCE_PAYMENTS_INDEX_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(FINANCE_PAYMENTS_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.$set(this.filters, "wait_approve_id", this.wait_approve_id);
    this.$set(this.filters, "approve_id", this.approve_id);
    this.$set(this.filters, "status_id", this.wait_approve_id);

    setTimeout(this.onHitBottom, 500);
  },
  watch: {
    "filters.status_id": {
      handler: function (newValue, oldValue) {
        this.onHitBottom();
      },
      deep: true,
    },
    sort() {
      this.filterResults();
    },
    order() {
      this.filterResults();
    },
  },
};
</script>

<style scoped></style>
